import {
  newTemplate, toParams,
} from '@level'

const templateQueryDefaults = `
  id
  slug
  type
  name
  updatedAt
  published
  publishedAt
  previewTemplateVariables
  teamDefaultFrom
  tags { name }
  previewConfig {
    body
    variables
    sections {
      body
      variables
      sharedSectionId
    }
  }
  liveConfig {
    body
    variables
    sections {
      body
      variables
      sharedSectionId
    }
  }
`

const createTemplateQuery = ({
  Project, name, slug, config, type, tags,
}) => `mutation {
  template: createTemplate(
    team: "${Project.team.slug}",
    project: "${Project.slug}",
    attributes: {
      type: ${type},
      ${toParams({ slug, name, tags: tags?.length ? tags.map(t => ({ name: t.name })) : null })},
      previewConfig: {
        ${toParams(config || newTemplate({ type }))}
      }
    }
  ) { slug }
}`

const templateQuery = ({ slug, Project }) => `
  template(slug: "${slug}", project: "${Project.slug}", team: "${Project.team.slug}") {
    ${templateQueryDefaults}
  }
`

const changelogType = ({ type, config }) => `
  createdAt
  updatedAt
  ${type}ConfigChangelog {
    publishedAt
    version
    ${config ? `config {
      body
      variables
      sections {
        body
        variables
        sharedSectionId
      }
    }` : ''}
  }
`

const templateChangelogQuery = ({ slug, Project, type = ['live', 'preview'], config = true }) => {
  let typeArray = Array.isArray(type) ? type : [type]
  return `
  template(slug: "${slug}", project: "${Project.slug}", team: "${Project.team.slug}") {
    ${typeArray.map((t) => changelogType({ type: t, config })).join('\n')}
  }
`
}

const deleteTemplateQuery = ({ id }) => `mutation {
  deleteTemplate(id: "${id}") {
    id
  }
}`

const revertTemplateQuery = ({ id }) => `mutation {
  revertTemplate(id: ${id}) { id } 
}`

const toTags = (tags) => (
  tags?.split ? tags.split(/,\s*/).reduce((acc, name) => {
    return name?.trim() ? [...acc, { name }] : acc
  }, []) : undefined
)

const updateTemplateConfigQuery = ({
  id, previewConfig,
}) => {
  const attributes = `, previewConfig: {
    ${toParams(previewConfig)}
  }`
  return `mutation {
  template: updateTemplate(id: ${id},
  attributes: { 
    ${attributes}
  }
) { id }
}`
}

const updateTemplateAttributesQuery = ({
  id, name, slug, tags,
}) => {
  const attributes = toParams({ name, slug, tags: toTags(tags) })
  // Tags: from 'tag, othertag' => [{name: 'tag'}, {name: 'othertag'}, ...]
  return `mutation {
  template: updateTemplate(id: ${id},
  attributes: {
    ${attributes}
  }
) { id, name, slug }
}`
}

const publishTemplateQuery = ({ id }) => `mutation { publishTemplate(id: ${id}) { id } }`

export {
  templateQuery,
  createTemplateQuery,
  updateTemplateAttributesQuery,
  updateTemplateConfigQuery,
  deleteTemplateQuery,
  publishTemplateQuery,
  revertTemplateQuery,
  templateChangelogQuery,
  templateQueryDefaults,
}
