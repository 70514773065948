import mustache from 'mustache'
import { renderMarkdown } from './markdown'
import { formatDateAsWords } from './date'
import {
  mapCallback, includeKeys, copyObject, sortByNestedValue,
} from './object'
import { uniqueArray, excludeItemsFromArray } from './array'
import {
  validColor, getContrast, sortByHex,
} from './color'

const templateStatusText = ({ published, publishedAt }) => {
  let icon
  let color
  let text
  if (!publishedAt) {
    icon = 'circle-minus'
    color = 'neutral-400'
    text = 'Draft'
  } else if (publishedAt && !published) {
    icon = 'circle-check'
    color = 'yellow-800'
    text = 'Live • Unpublished Changes'
  } else if (published) {
    icon = 'circle-check'
    color = 'green-600'
    text = 'Live'
  }
  return {
    icon,
    color,
    text,
  }
}

const templateUpdateText = ({ updatedAt, published, publishedAt }) => {
  if (published) return `Published ${formatDateAsWords(publishedAt)}`
  if (updatedAt) return `Updated ${formatDateAsWords(updatedAt)}`
  return null
}

const getColumnElement = ({ column, elementId }) => {
  const index = column.elements.findIndex((el) => el?.id === elementId)
  const element = column.elements[index]
  return (index !== -1) ? {
    column,
    element,
    elementId: element.id,
    elementIndex: index,
    elementType: element.type,
  } : null
}

const getSectionIndex = ({ config, section, sectionId = section?.variables?.id }) => (
  config.sections.findIndex(({ variables }) => (
    variables.id === sectionId
  ))
)

const getTemplateSection = ({ config, sectionId }) => {
  const sectionIndex = getSectionIndex({ config, sectionId })
  const section = config.sections[sectionIndex]
  return {
    sectionIndex,
    section,
  }
}

const getTemplateSectionElement = ({ config, sectionId, elementId }) => {
  const sectionIndex = getSectionIndex({ config, sectionId })
  const section = config.sections[sectionIndex]
  return section.variables.columns.reduce((acc2, col, index) => {
    if (acc2) return acc2
    const column = getColumnElement({ config, column: col, elementId })
    return column ? {
      ...column,
      section,
      sectionId: section.variables.id,
      sectionIndex,
      columnIndex: index,
      varPath: `columns.${index}.elements.${column.elementIndex}.config`,
    } : null
  }, null)
}

// Render section config variables through markdown
const renderSectionVariables = (obj) => (
  mapCallback({
    obj,
    overwrite: false,
    callback: ({ value, key: type }) => renderMarkdown(value, { type }),
  })
)

// Simplify writing a conditional output in mustache
// Examples:
// - { try: 'test' } => {{#test}}{{{test}}}{{/test}}
// - { try: 'test', then: 'value' } => {{#test}}value{{/test}}
// - { try: 'test', then: 'value', else: 'alt'}
//    => {{#test}}value{{/test}}{{^test}}alt{{/test}}
const mustacheCondition = ({
  try: tryVal, then = `{{{${tryVal}}}}`, else: elseVal,
}) => {
  const val = `{{#${tryVal}}}${then}{{/${tryVal}}}`
  if (elseVal) return `${val}{{^${tryVal}}}${elseVal}{{/${tryVal}}}`
  return val
}

const addToTheme = (valueProps, options = {}) => {
  const values = !Array.isArray(valueProps) ? [valueProps] : valueProps
  const { _theme = {} } = options
  const themeProps = {
    _theme,
  }
  values.forEach(({ name, type, value }) => {
    if (value && name && type) {
      const config = `{{{_theme.${name}.mustache}}}`
      // Section themes check user them and config _theme before supplying their own value
      themeProps[name] = config
      const val = {
        value,
        config,
        name,
        type,
        mustache: mustacheCondition({
          try: `theme.${name}`,
          else: mustacheCondition({
            try: `_theme.${name}.value`,
            else: value,
          }),
        }),
      }
      themeProps._theme = {
        ...themeProps._theme,
        [name]: val,
      }
    }
  })

  return themeProps
}

// Convert an object of colors into an array of theme properties
const addThemeColors = (colors, options) => {
  const props = Object.entries(colors).map(([name, value]) => ({ type: 'color', name, value }))
  return addToTheme(props, options)
}

const getThemeValue = ({ value, config }) => {
  const match = value?.match?.(/\{\{\{_theme\.([^.]+)\./)
  return (match) ? config.variables?._theme?.[match[1]]?.value : null
}

// When in preview mode we want templates to render their theme properties.
// In preview mode we typically show raw mustache variables unless users supply them.
// This would typically cause theme mustache conditionals to render into the style
// which will prevent colors from rendering.
// This funcction switches the theme vars so that the value is rendered
// instead of the mustache condition.
const templateThemePreview = ({ _theme = {}, userTheme = {}, sectionTheme = {} }) => {
  const previewTheme = {}
  Object.entries({ ...sectionTheme, ..._theme }).forEach(([key, value]) => {
    previewTheme[key] = { mustache: userTheme[key] || value.value }
    if (value?.type === 'color') {
      previewTheme[`_${key}_contrast`] = { mustache: getContrast(userTheme[key] || value.value) }
    }
  })
  return previewTheme
}

// When previewing a template, style configs pointing to _theme variables are replaced
// with the values from that theme or the user theme override values.
// This allows us to render an accurate theme without running mustache over a whole template
const previewThemeVariables = ({ style, _theme = {}, previewVars: { theme = {} } = {} }) => {
  if (!Object.values(_theme || {}).length) return style
  return Object.entries(style).reduce((acc, [prop, value]) => {
    const match = value?.match?.(/\{\{\{_theme\.([^.]+)\..+?\}\}\}/)
    if (!match) return { ...acc, [prop]: value }
    const newVal = theme?.[match[1]] || _theme?.[match[1]]?.value
    return {
      ...acc,
      [prop]: value.replace(match[0], newVal),
    }
  }, {})
}

const getThemeContrast = (_theme = {}) => (
  Object.entries(_theme).reduce((acc, [name, value]) => (
    (value?.type === 'color')
      ? {
        ...acc,
        [`${name}_contrast`]: {
          mustache: `{{{_theme.${name}_contrast.value}}}`,
          value: getContrast(value.value),
        },
      }
      : acc
  ), _theme)
)

// When previewing we want to ensure the theme variables are rendered
// But mutliple render cycles will overwrite user variables for non theme values
// This skips the mustache render, and uses a simple replace to bake theme values
const replaceThemeVariables = (variables, theme) => (
  JSON.parse(Object.entries(theme).reduce((acc, [name, val]) => (
    acc.replaceAll(`{{{_theme.${name}.mustache}}}`, val.mustache)
  ), JSON.stringify(variables)))
)

// Section body may point to section config variables
// This renders those variables into the section body
// If preview is true, theme values are rendered instead of conditional mustache
const preRenderSection = ({
  section,
  previewVars = {},
  _theme = {},
  preview = false,
}) => {
  let { theme: userTheme } = previewVars
  if (preview) {
    // Replace theme conditional mustache with values
    userTheme = templateThemePreview({ _theme, userTheme, sectionTheme: section.variables._theme })
  }
  const themePreview = { ..._theme, ...(section.variables._theme || {}), ...userTheme }
  return mustache.render(section.body, {
    ...previewVars,
    ...renderSectionVariables(replaceThemeVariables(section.variables, themePreview)),
    _theme: themePreview,
  })
}

// Render section configs into section body
const preRenderSections = ({
  sections = [],
  previewVars = {},
  _theme = {},
  preview = false,
}) => {
  return sections.reduce((acc, section) => acc + preRenderSection({
    section, previewVars, _theme, preview,
  }), '')
}

const renderPreview = (body, { previewVars = {}, _theme, ...rest }) => {
  const { theme = {}, ...userVars } = previewVars
  if (!Object.values(userVars).filter((v) => !!v)?.length) return body

  const vars = {
    ...userVars,
    ...{
      theme: getThemeContrast(theme),
      _theme: getThemeContrast(_theme),
    },
    ...rest,
  }
  return mustache.render(mustache.render(body, vars), vars)
}

const mapElementConfig = ({
  element, section, callback, overwrite = false,
}) => {
  mapCallback({
    obj: element.config,
    callback: (props) => callback({ ...props, section, element }),
    overwrite,
  })
  return element
}

const matchValue = (valA, valB) => (
  valA?.toLowerCase && valB?.toLowerCase
  && valA.toLowerCase() === valB.toLowerCase()
)

const mapSectionConfig = ({
  section,
  callback,
  overwrite = false,
}) => {
  mapCallback({
    obj: section.variables.config,
    callback: (props) => callback({ ...props, section }),
    overwrite,
  })
  section.variables.columns.map((col) => {
    col.elements.map((el) => mapElementConfig({
      element: el, section, callback, overwrite,
    }))
    return col
  })
  return section
}

const mapTemplateConfig = ({
  config,
  callback,
  overwrite = false,
  sharedSections = false,
}) => {
  mapCallback({
    obj: config.variables.style,
    callback,
    overwrite,
  })
  config.sections.map((section) => {
    if (!sharedSections && section.sharedSectionId) return section
    return mapSectionConfig({
      section, callback, overwrite,
    })
  })
  return config
}

const findColors = (colors) => ({
  key, value, section, element,
}) => {
  if (validColor(value)) {
    const val = value.toLowerCase()
    if (!colors.find((c) => matchValue(c.value, val))) {
      let from = 'template'
      if (section) from = 'section'
      if (element) from = element.type
      colors.push({ key, from, value: val })
    }
  }
  return value
}

const sectionColors = ({
  section,
  callback,
}) => {
  const colors = []
  mapSectionConfig({ section, callback: callback || findColors(colors) })
  return uniqueArray(colors)
}

// get colors from template config
const templateColors = ({
  config,
  callback,
}) => {
  const colors = []
  mapTemplateConfig({ config, callback: callback || findColors(colors) })
  return uniqueArray(colors)
}

const getThemeKey = (value) => (
  value?.match?.(/\{\{\{_theme\.([^.]+)\./)?.[1]
)

const themeValuesCallback = (values = []) => ({ value }) => {
  const match = getThemeKey(value)
  if (match) values.push(match)
}

const templateThemeValues = ({ config }) => {
  const values = []
  const callback = themeValuesCallback(values)
  mapTemplateConfig({ config, callback })
  return uniqueArray(values)
}

const sectionThemeValues = ({ section }) => {
  const values = []
  const callback = themeValuesCallback(values)
  mapSectionConfig({ section, callback })
  return uniqueArray(values)
}

const elementThemeValues = ({ element }) => {
  const values = []
  const callback = themeValuesCallback(values)
  mapElementConfig({ element, callback })
  return uniqueArray(values)
}

const injectThemeCallback = ({ _theme, revert = false }) => ({ value }) => {
  if (!value) return value

  if (revert) {
    // Replace config with theme value
    const themeValue = Object.values(_theme).find(({ config }) => config === value)?.value
    if (themeValue) return themeValue
  } else {
    // Replace value with theme config
    const themeConfig = Object.values(_theme).find((v) => matchValue(v.value, value))?.config
    if (themeConfig) return themeConfig
  }
  return value
}

const injectTemplateThemeValues = ({ config, _theme, revert = false }) => (
  mapTemplateConfig({
    config,
    callback: injectThemeCallback({ _theme, template: config, revert }),
    overwrite: true,
  })
)

const injectSectionThemeValues = ({ section, _theme, revert = false }) => {
  mapSectionConfig({
    section,
    callback: injectThemeCallback({ _theme, revert }),
    overwrite: true,
  })
}

const sortThemeColors = (_theme = {}) => sortByNestedValue(_theme, sortByHex, 'value')

const updateTemplateTheme = ({ config, _theme }) => {
  const currentTheme = config.variables._theme
  if (!_theme) {
    if (currentTheme) {
      injectTemplateThemeValues({
        config,
        _theme: currentTheme,
        revert: true,
      })
      delete config.variables._theme
    }
  } else {
    if (currentTheme) {
      // Find template keys that are not present in the new theme
      const toBeRemoved = excludeItemsFromArray(Object.keys(currentTheme), Object.keys(_theme))
      // If found, replace links to theme config with directly embedded values
      if (toBeRemoved.length) {
        injectTemplateThemeValues({
          config,
          _theme: includeKeys(currentTheme, toBeRemoved),
          revert: true,
        })
      }
    }
    // Copy object to prevent template config from being affected by operations on _theme object
    config.variables._theme = sortThemeColors(_theme)
    injectTemplateThemeValues({ config, _theme })
  }
}

const updateSectionTheme = ({ section, _theme }) => {
  const currentTheme = section.variables._theme
  if (!_theme) {
    if (currentTheme) {
      injectSectionThemeValues({
        section,
        _theme: currentTheme,
        revert: true,
      })
      delete section.variables._theme
    }
  } else {
    if (currentTheme) {
      // Find template keys that are not present in the new theme
      const toBeRemoved = excludeItemsFromArray(Object.keys(currentTheme), Object.keys(_theme))
      // If found, replace links to theme config with directly embedded values
      if (toBeRemoved.length) {
        injectSectionThemeValues({
          section,
          _theme: includeKeys(currentTheme, toBeRemoved),
          revert: true,
        })
      }
    }
    // Copy object to prevent config from being affected by operations on _theme object
    section.variables._theme = copyObject(_theme)
    injectSectionThemeValues({ section, _theme })
  }
}

const removeSectionTheme = ({ config, section }) => {
  const sectionTheme = section.variables._theme

  if (sectionTheme) {
    // Find template keys that are not present in template theme
    const addToTemplate = excludeItemsFromArray(
      Object.keys(sectionTheme),
      Object.keys(config.variables._theme),
    )
    if (addToTemplate.length) {
      // If found, replace links to theme config with directly embedded values
      const missingThemeProps = addToTemplate.reduce((acc, name) => (
        [...acc, { name, ...sectionTheme[name] }]
      ), [])
      const { _theme } = addToTheme(missingThemeProps, { _theme: config.variables._theme })
      updateTemplateTheme({ config, _theme })
    }
  }

  delete section.variables._theme
}

// Finds all template theme values present in the section and
// embeds them in the section as a theme.
const embedSectionTheme = ({ section, config }) => {
  if (!config.variables._theme) return section
  const values = sectionThemeValues({ section })
  if (values.length) {
    section.variables._theme = {}
    values.forEach((val) => {
      const themeVal = copyObject(config.variables._theme[val])
      section.variables._theme[val] = themeVal
    })
  }
  return section
}

const themeColors = (_theme) => (
  Object.values(_theme).reduce((acc, props) => (
    props.type === 'color' ? [...acc, props.value] : acc
  ), [])
)

// Get an element and its place in a section
// element, elementId: Accepts an element object or an elementId
// Returns: a sectionElement ({ section, element, columnIndex, elementIndex })
const getTemplateElement = ({ config, sectionId, elementId }) => {
  if (!elementId) return {}
  if (sectionId) return getTemplateSectionElement({ config, sectionId, elementId })

  return config.sections.reduce((acc, section, sectionIndex) => (
    acc || section.variables.columns.reduce((acc2, col, index) => {
      if (acc2) return acc2
      const column = getColumnElement({ column: col, elementId })
      return column ? {
        ...column,
        section,
        sectionId: section.variables.id,
        sectionIndex,
        columnIndex: index,
        varPath: `columns.${index}.elements.${column.elementIndex}.config`,
      } : acc
    }, null)
  ), null)
}

export {
  renderPreview,
  preRenderSection,
  preRenderSections,
  addToTheme,
  getTemplateSection,
  getTemplateElement,
  templateStatusText,
  templateUpdateText,
  sectionColors,
  templateColors,
  injectTemplateThemeValues,
  injectSectionThemeValues,
  previewThemeVariables,
  templateThemeValues,
  sectionThemeValues,
  embedSectionTheme,
  updateTemplateTheme,
  updateSectionTheme,
  removeSectionTheme,
  getColumnElement,
  elementThemeValues,
  mapSectionConfig,
  mapTemplateConfig,
  themeColors,
  getThemeValue,
  getThemeKey,
  sortThemeColors,
  addThemeColors,
}
