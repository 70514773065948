import React from "react";
import PropTypes from "prop-types";

import {
  Stack,
  P,
  Input,
  HiddenInput,
  Block,
  BlankSlate,
  useFormRef,
  templateConfigDefaults,
} from "@level";

const blankConfig = {
  body: "",
  variables: templateConfigDefaults.template,
  sections: [],
};

const BlankTemplate = ({ canChooseName = true }) => {
  const { setValue, getValues } = useFormRef();
  const nameValue = getValues("name");

  React.useEffect(() => {
    setValue("name", null);
    setValue("config", blankConfig);
    setValue("template", null);
  }, [nameValue]);

  return (
    <Block space={8} className="theme-gallery-content">
      {canChooseName ? (
        <Stack gap={5}>
          <Input
            name="name"
            label="Name your template"
            placeholder="My Template"
            required
          />
          <P>
            Templates hold the design & formatting that you would like you use
            for a message. You will need a separate template for each message
            you send.
          </P>
        </Stack>
      ) : (
        <Stack gap={5}>
          <BlankSlate
            icon="square-dashed-circle-plus"
            heading="Choose Your Own Adventure"
            description="If you know your way around the template builder, add sections and elements to start from scratch."
            style={{ margin: "0 auto" }}
          />
          <HiddenInput name="name" value="Blank" />
        </Stack>
      )}
    </Block>
  );
};

BlankTemplate.propTypes = {
  canChooseName: PropTypes.bool,
};

export { BlankTemplate };
